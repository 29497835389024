// react
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// atoms
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
import { userAtom } from '@atoms/userAtom';
import { createdObjectUserAtom } from '@atoms/createdObjectUserAtom';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';
// hooks
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDebounce from '@hooks/debounce/useDebounce';
import { useForm, useWatch } from 'react-hook-form';
import { useOnClickOutside } from '@hooks/clickOutside/useOnClickOutside';
// mutations
import {
  useCancelAppointment,
  useConfirmAppointment,
  useCreateRecurringAppointment,
  useCreateRegularAppointment,
  useDeclineAppointment,
  useDeleteRecurringAppointment,
  useUpdateAppointment,
  useUpdateRecurringAppointment
} from '@api/mutations/appointments/appointments';
// queries
import { useAvailableTimePrices, useCourtSport } from '@api/queries/priceRules/priceRules';
import {
  useAppointmentByID,
  useRecurringAppointmentByID
} from '@api/queries/appointments/appointments';
// interfaces
import {
  CreateRecurringAppointmentErrorType,
  CreateRegularAppointmentErrorType,
  RecurringAppointment,
  RegularAppointmentType,
  RegularAppointmentCreateRequest,
  RegularAppointmentResponseType,
  RecurringAppointmentResponseType,
  RecurringAppointmentCreateRequest,
  CalendarWorkingHoursType
} from '@interfaces/appointments/appointments';
import { SportCenterWithCourtSportResponse } from '@interfaces/SportCenters/SportCenter';
import {
  CourtSportData,
  CourtSportError,
  CourtSportErrorResponse,
  CreatePriceRuleResponse,
  TimePricesType
} from '@interfaces/priceRules/priceRules';
import { ErrorResponse } from '@interfaces/apiResponse';
//helpers
import {
  getRecurringAppointmentData,
  getSingleAppointmentData,
  setCourtSportAndDescForAppointmentByCourtSportSuccess,
  getDataFromRecurringAppointment,
  getDuartionTimeForAppointmentBasedOnStartTime,
  getStartTimeForAppointmentBasedOnTheGivenDate,
  getTranslateName
} from '@helpers/utility';
//dayjs
import dayjs, { Dayjs } from 'dayjs';
//enums
import { RoleEnum } from '@enum/roleEnum';

const useAppointments = (
  open: boolean,
  onClose: () => void,
  calendarWorkingHours: CalendarWorkingHoursType,
  selectedCourt?: string | number,
  appointmentID?: number,
  dateAppointment?: Dayjs | null,
  startAppointment?: string,
  reccuringAppointmentId?: number,
  durationAppointment?: string | number
) => {
  const defaultRegularAppointment: RegularAppointmentCreateRequest = {
    court_sport_id: '',
    regularAppointment_date: dateAppointment ? dateAppointment : dayjs(),
    datetime_start: '',
    minutes: '',
    user_id: '',
    description: '',
    regularAppointment_appendices: ''
  };

  const defaultRecurringAppointment: RecurringAppointmentCreateRequest = {
    court_sport_id: '',
    name: '',
    date_from: dateAppointment ? dateAppointment : dayjs(),
    date_to: dayjs(),
    time_start: '',
    minutes: '',
    price: '',
    user_id: '',
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    workweek: false,
    weekend: false
  };

  const params = useParams();
  const { t } = useTranslation();
  const sportCenterID: number | undefined = params.sportCenterId
    ? parseInt(params.sportCenterId)
    : undefined;
  const [courtSport, setCourtSport] = useState<CourtSportData[]>([]);
  const [openUsersMenu, setOpenUsersMenu] = useState<boolean>(false);
  const refUsersMenu: MutableRefObject<HTMLElement | null> = useRef(null);
  const [userInput, setUserInput] = useState<{ id: number | string; name: string }>({
    id: '',
    name: ''
  });
  const [durationTime, setDurationTime] = useState<TimePricesType[]>([]);
  const [durationTimeRecurring, setDurationTimeRecurring] = useState<TimePricesType[]>([]);
  const [timePickerTimes, setTimePickerTimes] = useState<{ minTime: number; maxTime: number }>({
    minTime: 0,
    maxTime: 0
  });
  const [timePickerTimesRecurring, setTimePickerTimesRecurring] = useState<{
    minTime: number;
    maxTime: number;
  }>({
    minTime: 0,
    maxTime: 0
  });
  const [selectedAppointmentData, setSelectedAppointmentData] =
    useState<RegularAppointmentCreateRequest>({
      ...defaultRegularAppointment
    });
  const [selectedRecurringAppointmentData, setSelectedRecurringAppointmentData] =
    useState<RecurringAppointmentCreateRequest>({
      ...defaultRecurringAppointment
    });
  const [workingDays, setWorkingDays] = useState<string[]>([]);
  const setPopup = useSetRecoilState(popupAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);
  const user = useRecoilValue(userAtom);
  const [createdObjectUser, setCreatedObjectUser] = useRecoilState(createdObjectUserAtom);
  const appointmentForm = useRecoilValue(appointmentFormAtom);
  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserInput({ id: '', name: event.target.value });
  };
  const [editAppointment, setEditAppointment] = useState<boolean>(false);
  const lng = localStorage.getItem('lng');
  const debounceValue = useDebounce(userInput.name, 400);

  useOnClickOutside(refUsersMenu, () => setOpenUsersMenu(false));

  const {
    handleSubmit: handleRegularAppointmentSubmit,
    control: controlRegularAppointment,
    setError: setRegularAppointmentError,
    getValues: getRegularAppointmentValues,
    setValue: setRegularAppointmentValue,
    reset: resetRegularForm
  } = useForm<RegularAppointmentCreateRequest>({
    defaultValues: selectedAppointmentData,
    mode: 'onBlur'
  });
  const {
    handleSubmit: handleRecurringAppointmentSubmit,
    control: controlRecurringAppointment,
    setError: setRecurringAppointmentError,
    getValues: getRecurringAppointmentValues,
    setValue: setRecurringAppointmentValue,
    reset: resetRecurringForm,
    formState: { isSubmitted: isRecurringAppointmentSubmitted }
  } = useForm<RecurringAppointmentCreateRequest>({
    defaultValues: selectedRecurringAppointmentData,
    mode: 'onBlur'
  });
  const selectedCourtSportRegular = useWatch({
    control: controlRegularAppointment,
    name: 'court_sport_id'
  });
  const selectedTimeRegular = useWatch({
    control: controlRegularAppointment,
    name: 'datetime_start'
  });
  const selectedDateRegular = useWatch({
    control: controlRegularAppointment,
    name: 'regularAppointment_date'
  });
  const selectedCourtSportRecurring = useWatch({
    control: controlRecurringAppointment,
    name: 'court_sport_id'
  });
  const selectedTimeRecurring = useWatch({
    control: controlRecurringAppointment,
    name: 'time_start'
  });
  const selectedDateRecurring = useWatch({
    control: controlRecurringAppointment,
    name: 'date_from'
  });
  function onCloseFormAppointments() {
    setSelectedAppointmentData({
      ...defaultRegularAppointment,
      regularAppointment_date: dayjs()
    });
    setSelectedRecurringAppointmentData({
      ...defaultRecurringAppointment,
      date_from: dayjs()
    });
    setUserInput({ id: '', name: '' });
    resetRegularForm({
      ...defaultRegularAppointment
    });
    resetRecurringForm({
      ...defaultRecurringAppointment
    });
    setEditAppointment(false);
    setWorkingDays([]);
    setCreatedObjectUser(null);
  }

  const { mutate: createRegularAppointment } = useCreateRegularAppointment(
    handleSuccessCreateRegularAppointment,
    handleErrorCreateRegularAppointment
  );

  const { mutate: editAppointmentData } = useUpdateAppointment(
    handleSuccessCreateRegularAppointment,
    handleErrorCreateRegularAppointment
  );

  function handleSuccessCreateRegularAppointment(data: RegularAppointmentType) {
    onClose();
  }

  function handleErrorCreateRegularAppointment(err: CreateRegularAppointmentErrorType) {
    if (err.errors) {
      if (err.errors.user_id) {
        setPopup({
          open: true,
          title: err.errors.user_id[0],
          content: '',
          variant: 'error'
        });
      } else {
        for (const key in err.errors) {
          setRegularAppointmentError(
            key as keyof RegularAppointmentCreateRequest,
            {
              type: 'custom',
              message: err.errors[key][0]
            },
            {
              shouldFocus: true
            }
          );
        }
      }
    }
  }

  const { mutate: createRecurringAppointment } = useCreateRecurringAppointment(
    handleSuccessCreateRecurringAppointment,
    handleErrorCreateRecurringAppointment
  );

  function handleSuccessCreateRecurringAppointment(data: RecurringAppointment) {
    onClose();
  }

  function handleErrorCreateRecurringAppointment(err: CreateRecurringAppointmentErrorType) {
    if (err.errors) {
      if (err.errors.user_id) {
        setPopup({
          open: true,
          title: err.errors.user_id[0],
          content: '',
          variant: 'error'
        });
      } else {
        for (const key in err.errors) {
          if (key === 'datetime_start') {
            setRecurringAppointmentError(
              'date_from',
              {
                type: 'custom',
                message: err.errors[key][0]
              },
              {
                shouldFocus: true
              }
            );
          } else {
            setRecurringAppointmentError(
              key as keyof RecurringAppointmentCreateRequest,
              {
                type: 'custom',
                message: err.errors[key][0]
              },
              {
                shouldFocus: true
              }
            );
          }
        }
      }
    }
  }
  const onSingleAppointmentSuccess = (res: RegularAppointmentResponseType) => {
    let regularAppointment = res.data.data;
    //get start time of the appointment in right format
    let startTimeAppointment = `${dayjs(regularAppointment.datetime_start).format('HH')}:${dayjs(
      regularAppointment.datetime_start
    ).format('mm')}`;
    //find duration time from end time and start time of the appointment
    let durationAppointment =
      dayjs(res.data.data.datetime_end).diff(dayjs(res.data.data.datetime_start)) / 1000 / 60;
    setUserInput({ id: regularAppointment.user.id, name: regularAppointment.user.name });
    //reset form with the selected appointment data
    resetRegularForm({
      court_sport_id: regularAppointment.court_sport_id,
      regularAppointment_date: dayjs(regularAppointment.datetime_start),
      datetime_start: startTimeAppointment,
      minutes: durationAppointment,
      user_id: regularAppointment.user.id,
      description: regularAppointment.description ? regularAppointment.description : ''
    });
    setSelectedAppointmentData(getSingleAppointmentData(res));
  };

  const onSingleAppointmentError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { data: selectedAppointmentByID } = useAppointmentByID(
    appointmentID,
    onSingleAppointmentSuccess,
    onSingleAppointmentError
  );
  // update permanent appointment
  const onEditRecurringAppointmentSuccess = (data: RecurringAppointmentResponseType) => {
    setPopup({
      open: false,
      title: '',
      content: '',
      variant: 'warning'
    });
    onClose();
  };

  const { mutate: editRecurringAppointment } = useUpdateRecurringAppointment(
    onEditRecurringAppointmentSuccess,
    handleErrorCreateRecurringAppointment
  );
  const onSuccessPermanentAppointment = (data: RecurringAppointmentResponseType) => {
    let permanentAppointment = data.data.data;
    setWorkingDays(getDataFromRecurringAppointment(permanentAppointment, t).arrayOfDays);
    setUserInput({ id: permanentAppointment.user.id, name: permanentAppointment.user.name });
    //reset form with the selected appointment data
    resetRecurringForm({
      id: reccuringAppointmentId,
      court_sport_id: permanentAppointment.court_sport.id,
      name: permanentAppointment.name,
      date_from: dayjs(permanentAppointment.date_from),
      date_to: dayjs(permanentAppointment.date_to),
      time_start: permanentAppointment.time_start,
      minutes: permanentAppointment.minutes,
      price: permanentAppointment.price,
      user_id: permanentAppointment.user.id,
      mon: permanentAppointment.mon,
      tue: permanentAppointment.tue,
      wed: permanentAppointment.wed,
      thu: permanentAppointment.thu,
      fri: permanentAppointment.fri,
      sat: permanentAppointment.sat,
      sun: permanentAppointment.sun,
      workweek:
        permanentAppointment.mon &&
        permanentAppointment.tue &&
        permanentAppointment.wed &&
        permanentAppointment.thu &&
        permanentAppointment.fri,
      weekend: permanentAppointment.sat && permanentAppointment.sun
    });
    setSelectedRecurringAppointmentData(getRecurringAppointmentData(data));
  };

  const onErrorPermanentAppointment = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const {} = useRecurringAppointmentByID(
    reccuringAppointmentId
      ? reccuringAppointmentId
      : Number(selectedAppointmentByID?.data.data?.recurring_appointment_id),
    onSuccessPermanentAppointment,
    onErrorPermanentAppointment
  );

  const onRegularAppointmentSubmit = (data: RegularAppointmentCreateRequest) => {
    let dateAppointment = dayjs(getRegularAppointmentValues('regularAppointment_date')).format(
      'YYYY-MM-DD'
    );
    let sendData = {
      court_sport_id: data.court_sport_id,
      datetime_start: `${dateAppointment} ${data.datetime_start}`,
      minutes: data.minutes,
      user_id: userInput.id,
      description: data.description
    };
    if (appointmentID && editAppointment) {
      editAppointmentData({ ...sendData, appointment_id: appointmentID });
    } else {
      createRegularAppointment(sendData);
    }
  };

  const onRecurringAppointmentSubmit = (data: RecurringAppointmentCreateRequest) => {
    let dateFromAppointment = dayjs(getRecurringAppointmentValues('date_from'))?.format(
      'YYYY-MM-DD'
    );
    let dateToAppointment = dayjs(getRecurringAppointmentValues('date_to'))?.format('YYYY-MM-DD');
    [];
    let sendData: RecurringAppointmentCreateRequest = {
      court_sport_id: data.court_sport_id,
      name: data.name,
      date_from: dateFromAppointment ? dateFromAppointment : '',
      date_to: dateToAppointment ? dateToAppointment : '',
      time_start: data.time_start,
      minutes: data.minutes,
      price: data.price,
      user_id: userInput.id,
      mon: data.mon,
      tue: data.tue,
      wed: data.wed,
      thu: data.thu,
      fri: data.fri,
      sat: data.sat,
      sun: data.sun
    };
    if (
      reccuringAppointmentId ||
      (selectedAppointmentByID &&
        selectedAppointmentByID.data.data.recurring_appointment_id !== null)
    ) {
      return setPopup({
        open: true,
        title: t('calendar.permanentAppointment.edit_popUp_title'),
        content: t('calendar.permanentAppointment.edit_popUp_subtitle'),
        variant: 'info',
        onClick: () =>
          editRecurringAppointment({
            ...sendData,
            id: reccuringAppointmentId
              ? reccuringAppointmentId
              : Number(selectedAppointmentByID?.data.data.recurring_appointment_id)
          })
      });
    } else {
      return createRecurringAppointment(sendData);
    }
  };

  const onGetCourtSportSuccess = (data: SportCenterWithCourtSportResponse) => {
    if (selectedCourt) {
      data.data.courts = data.data.courts.filter(court => court.id === selectedCourt);
    }
    let tempArr: CourtSportData[] = [];
    // we compare the court/sports of the sport center with the assigned court/sports of the logged in coach,
    // in order to find the name of the court/sports of the logged in coach,
    // because we for logged in coach only get court/sport id without court/sport name from backend
    if (currentCMS?.role === RoleEnum.COACH) {
      let coachInfoForSelectedSportCenter = user?.coach_in_sport_centers.filter(
        u => u.id === sportCenterID
      );
      data.data.courts.map(item => {
        item.court_sports.map(coSpo => {
          coachInfoForSelectedSportCenter?.map(coachInfo => {
            coachInfo.coach_info.court_sports?.map(coachCoSpo => {
              if (coSpo.id === coachCoSpo.id) {
                return tempArr.push({
                  id: coSpo.id,
                  name: `${coSpo.court.name} - ${getTranslateName(
                    lng,
                    coSpo.sport.name_translates,
                    coSpo.sport.name
                  )}`
                });
              }
            });
          });
        });
      });
      setCourtSport(tempArr);
      if (tempArr && tempArr.length === 1) {
        setRegularAppointmentValue('court_sport_id', tempArr[0].id);
        setRecurringAppointmentValue('court_sport_id', tempArr[0].id);
      }
      if (startAppointment && dateAppointment && durationAppointment) {
        setRegularAppointmentValue('datetime_start', startAppointment);
        setRegularAppointmentValue('regularAppointment_date', dateAppointment);
        setRegularAppointmentValue('minutes', durationAppointment);
        setRecurringAppointmentValue('time_start', startAppointment);
        setRecurringAppointmentValue('date_from', dateAppointment);
        setRecurringAppointmentValue('minutes', durationAppointment);
      }
    } else {
      setCourtSportAndDescForAppointmentByCourtSportSuccess(
        data,
        setCourtSport,
        setRegularAppointmentValue,
        setRecurringAppointmentValue,
        startAppointment,
        dateAppointment,
        lng,
        durationAppointment
      );
    }
    setTimePickerTimes(
      getStartTimeForAppointmentBasedOnTheGivenDate(
        dayjs(selectedDateRegular),
        calendarWorkingHours
      )
    );
    setTimePickerTimes(
      getStartTimeForAppointmentBasedOnTheGivenDate(
        dayjs(selectedDateRegular),
        calendarWorkingHours
      )
    );
  };

  function onGetCourtSportError(err: CourtSportError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { refetch: refetchCourtSport } = useCourtSport(
    sportCenterID,
    onGetCourtSportSuccess,
    onGetCourtSportError
  );

  const { mutate: confirmAppointment } = useConfirmAppointment(handleSuccess, handleError);

  const { mutate: declineAppointment } = useDeclineAppointment(handleSuccess, handleError);

  const { mutate: cancelAppointment } = useCancelAppointment(handleSuccess, handleError);

  const { mutate: deletePermanentAppointment } = useDeleteRecurringAppointment(
    handleSuccess,
    onDeletePermanentAppointmentError
  );

  function handleSuccess() {
    onClose();
  }

  function handleError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  function onDeletePermanentAppointmentError(error: ErrorResponse) {
    setPopup({
      open: true,
      title: error.message,
      content: '',
      variant: 'error'
    });
  }

  const handleSuccessAvailable = (data: CreatePriceRuleResponse) => {
    if (currentCMS?.role !== RoleEnum.COACH) {
      setDurationTime(data.data.time_prices);
    } else {
      setDurationTime(
        getDuartionTimeForAppointmentBasedOnStartTime(timePickerTimes, selectedTimeRegular)
      );
    }
  };

  const handleErrorAvailable = (err: CourtSportErrorResponse) => {
    setPopup({
      open: true,
      title: err.response.data.message,
      content: '',
      variant: 'error'
    });
  };

  const {} = useAvailableTimePrices(
    +selectedCourtSportRegular,
    currentCMS?.role !== RoleEnum.COACH
      ? `${selectedDateRegular?.format('YYYY-MM-DD')} ${selectedTimeRegular}`
      : '',
    handleSuccessAvailable,
    handleErrorAvailable
  );

  useEffect(() => {
    if (!open) {
      onCloseFormAppointments();
    }
    if (open) {
      setTimePickerTimes(
        getStartTimeForAppointmentBasedOnTheGivenDate(
          dateAppointment ? dateAppointment : dayjs(),
          calendarWorkingHours
        )
      );
      setTimePickerTimesRecurring(
        getStartTimeForAppointmentBasedOnTheGivenDate(
          dateAppointment ? dateAppointment : dayjs(),
          calendarWorkingHours
        )
      );
      refetchCourtSport();
      setDurationTime([
        { minutes: 30, price: '' },
        { minutes: 60, price: '' },
        { minutes: 90, price: '' },
        { minutes: 120, price: '' },
        { minutes: 150, price: '' },
        { minutes: 180, price: '' }
      ]);
      setDurationTimeRecurring([
        { minutes: 30, price: '' },
        { minutes: 60, price: '' },
        { minutes: 90, price: '' },
        { minutes: 120, price: '' },
        { minutes: 150, price: '' },
        { minutes: 180, price: '' }
      ]);

      if (currentCMS?.role === RoleEnum.COACH && user)
        setUserInput({ id: user.id, name: user.name });
    }
  }, [open]);

  useEffect(() => {
    setDurationTime(
      getDuartionTimeForAppointmentBasedOnStartTime(timePickerTimes, selectedTimeRegular)
    );
    setDurationTimeRecurring(
      getDuartionTimeForAppointmentBasedOnStartTime(timePickerTimesRecurring, selectedTimeRecurring)
    );
  }, [selectedTimeRegular, selectedTimeRecurring]);

  useEffect(() => {
    setTimePickerTimes(
      getStartTimeForAppointmentBasedOnTheGivenDate(
        dayjs(selectedDateRegular),
        calendarWorkingHours
      )
    );
    setTimePickerTimesRecurring(
      getStartTimeForAppointmentBasedOnTheGivenDate(
        dayjs(selectedDateRecurring),
        calendarWorkingHours
      )
    );
  }, [selectedDateRegular, selectedDateRecurring]);

  useEffect(() => {
    setRegularAppointmentValue('user_id', userInput.id);
    setRecurringAppointmentValue('user_id', userInput.id);
  }, [userInput]);

  useEffect(() => {
    if (createdObjectUser) {
      setUserInput(createdObjectUser);
    }
    if (appointmentForm && appointmentForm.formType === 'regularAppointment') {
      resetRegularForm(appointmentForm.formObject);
    }
    if (appointmentForm && appointmentForm.formType === 'permanentAppointment') {
      resetRecurringForm(appointmentForm.formObject);
    }
  }, []);

  return {
    controlRegularAppointment,
    handleRegularAppointmentSubmit,
    onRegularAppointmentSubmit,
    getRegularAppointmentValues,
    controlRecurringAppointment,
    handleRecurringAppointmentSubmit,
    onRecurringAppointmentSubmit,
    getRecurringAppointmentValues,
    setRecurringAppointmentValue,
    isRecurringAppointmentSubmitted,
    courtSport,
    openUsersMenu,
    setOpenUsersMenu,
    refUsersMenu,
    userInput,
    setUserInput,
    handleSearch,
    debounceValue,
    durationTime,
    timePickerTimes,
    workingDays,
    setWorkingDays,
    selectedAppointmentByID,
    confirmAppointment,
    declineAppointment,
    cancelAppointment,
    setSelectedRecurringAppointmentData,
    editAppointment,
    setEditAppointment,
    onCloseFormAppointments,
    deletePermanentAppointment,
    durationTimeRecurring,
    timePickerTimesRecurring
  };
};

export default useAppointments;

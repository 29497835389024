// hooks
import useAdminUsers from '@hooks/adminUsers/useAdminUsers';

// components
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

const AddAdminUsers = () => {
  const { handleCancel, handleSubmit, control, t, isDirty, isValid } = useAdminUsers();

  return (
    <Box p={3} component="form" onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          title={t('personalData')}
          subheader={t('adminUsers.add_user_subtitle')}
          sx={{ paddingBottom: 0 }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="user_info.first_name"
                control={control}
                defaultValue=""
                rules={{ required: t('errorMessage').toString() }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    error={invalid}
                    {...field}
                    label={t('name') + ' *'}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user_info.last_name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    error={invalid}
                    {...field}
                    label={t('surname')}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user_info.email"
                control={control}
                defaultValue=""
                rules={{ required: t('errorMessage').toString() }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    error={invalid}
                    label={t('email') + ' *'}
                    type="email"
                    placeholder="email@mail.com"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user_info.phone"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="phone"
                    label={t('phone_number')}
                    placeholder="+3816xxxxxxx"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user_info.date_of_birth"
                control={control}
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      label={t('date_of_birth')}
                      format="DD.MM.YYYY"
                      sx={{ width: '100%' }}
                      {...field}
                      defaultValue={null}
                    />
                    {error && <FormHelperText color="error">{error.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user_info.gender"
                defaultValue={''}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <FormControl fullWidth error={invalid}>
                    <InputLabel>{t('gender')}</InputLabel>
                    <Select {...field} label={t('gender')}>
                      <MenuItem value="male">{t('male')}</MenuItem>
                      <MenuItem value="female">{t('female')}</MenuItem>
                    </Select>
                    {error && <FormHelperText color="error">{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        <Button variant="text" color="primary" onClick={handleCancel}>
          {t('quit')}
        </Button>

        <Button type="submit" variant="contained" disabled={!isDirty && !isValid}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddAdminUsers;
